<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Discussion Closed
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Discussion Closed
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      outlined
                      dense
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchApplicationDiscussion()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Category"
                      v-model="search.category_id"
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.category_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchApplicationDiscussion()"
                      clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Ticket Read"
                      v-model="search.is_read"
                      :items="read_types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @input="search.is_read = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchApplicationDiscussion()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchApplicationDiscussion()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                      @click="searchApplicationDiscussion()"
                      class="mt-1 btn btn-primary"
                      style="color: #fff"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="text-left">
                  <th>Name</th>
                  <th>Category</th>
                  <th>Added Date</th>
                  <th>Published Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody class="mt-5">
                <template>
                  <tr v-for="(item, index) in discussions" :key="index">
                    <td class="px-2">
                      <a @click="viewApplicationDiscussionThread(item)" class="d-flex align-items-center">
                        <div class="symbol symbol-35px me-3">
                          <div class="symbol-label bg-light-danger">
                            <span class="text-danger">{{ item.symbol_label }}</span>
                          </div>
                        </div>
                        <span class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ item.name }}</span>
                      </a>
                    </td>
                    <td>
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{item.category_name }}
                      </span>
                    </td>
                    <td>
                      <a  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{ item.formatted_created_at ? item.formatted_created_at : '--'}}
                      </a>
                    </td>
                    <td class="w-100px text-end fs-7 pe-9">
                      <span class="fw-bold">{{item.readable_created_at}}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="goToStudent(item.user_id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                <span class="navi-text">View Student</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="deleteDiscussion(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="discussions.length == 0">
                    <td colspan="6" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="discussions.length > 0"
                  class="pull-right mt-7"
                  @input="getAllApplicationDiscussion"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ApplicationDiscussionService from "@/services/application/discussion/ApplicationDiscussionService";
import ApplicationDiscussionCategoryService
  from "@/services/application/discussion/category/ApplicationDiscussionCategoryService";

const applicationDiscussion = new ApplicationDiscussionService();
const discussionCategory=new ApplicationDiscussionCategoryService();
export default {
  data() {
    return {
      discussions:[],
      categories:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      search:{
        name: '',
        category_id: '',
        is_read: '',
        is_active: '',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Read tickets', value: '1'},
        {name: 'Not read tickets', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        {name: 'Refund', value: 'refund'},
        {name: 'Payment', value: 'payment'},
        {name: 'Feedback', value: 'feedback'},
        {name: 'Other', value: 'other'},
      ],
    }
  },
  methods:{
    goToStudent(userId) {
      this.$router.push({
        name: 'student-profile',
        params: {user_id: userId}
      })
    },

    getAllApplicationCategory() {
      discussionCategory
          .all()
          .then((response) => {
            this.categories = response.data.discussionCategories;
          })
          .catch(() => {});
    },
    getAllApplicationDiscussion(){
      this.loading =  true;
      applicationDiscussion
          .getAllDiscussionClosed(this.search,this.page)
          .then(response => {
            this.discussions = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.getUnReadTicket();
          })
          .catch((err) => {});
    },
    searchApplicationDiscussion(){
      this.getAllApplicationDiscussion();
    },
    viewApplicationDiscussionThread(ticket){
      this.$router.push({
        name:'discussion-view',
        params:{discussionId: ticket.id}
      });
    },
    deleteDiscussion(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            applicationDiscussion
                .delete(id)
                .then((response) => {
                  this.getAllApplicationDiscussion()
                  this.$snotify.success("Discussion Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  },
  mounted() {
    this.getAllApplicationDiscussion();
    this.getAllApplicationCategory();
  }
}
</script>